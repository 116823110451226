@import url(http://fonts.googleapis.com/css?family=Lato:100,300,400,700,900,100italic,300italic,400italic,700italic,900italic);
@import url(http://fonts.googleapis.com/css?family=Open+Sans:300italic,400italic,600italic,700italic,800italic,700,300,600,800,400);

::selection {
  background-color: #0F4B7F;
  color: #fff; }

::-moz-selection {
  background-color: #0F4B7F;
  color: #fff; }

body, html{
	font-family: 'Lato', sans-serif;
	text-rendering: optimizeLegibility !important;
	-webkit-font-smoothing: antialiased !important;
	color: #363636;
}

h1{
	text-transform: uppercase;
}
h1 strong{
	font-weight: 900;
}
h2{
	text-transform: uppercase;
	margin:  0;
}
h3{
font-size: 18px;
font-weight: 900;
}
h4{
	text-transform: uppercase;
}
h5{
	text-transform: uppercase;
	font-weight: 700;
	line-height: 20px;
}
p{
	font-family: 'Open Sans', sans-serif;
}

.branding {
	color: #A6BD0D;
}

p.intro{
	font-size: 16px;
	margin: 12px 0 0;
	line-height: 28px;
	font-family: 'Open Sans', sans-serif;
	font-weight: normal;
}
a{ color: #0E4D83;}
a:hover,
a:focus{
	text-decoration: none;
}

#tf-services a:hover,
#tf-services a:focus{
	text-decoration: underline;
}

small {
	font-size: 16px;
}

.section-title hr{
	border-color: #A7BE0E;
	border-width: 4px;
	width: 60px;
	float: left;
	clear: both;
}
.clearfix:after {
     visibility: hidden;
     display: block;
     font-size: 0;
     content: " ";
     clear: both;
     height: 0;
     }
.clearfix { display: inline-block; }
* html .clearfix { height: 1%; }
.clearfix { display: block; }
ul, ol{
	padding: 0;
	webkit-padding: 0;
	moz-padding: 0;
}

.modal-title {
    font-size: 24px;
    font-weight: bold;
}
.modal-dialog{
  z-index: 100000;
}

.modal-body {
    font-size: 16px;
    line-height: 1.6;
}

.btn-default {
    background: #A7BE0E;
    border: 0;
    border-radius: 0;
    padding: 10px 40px;
    color: #ffffff;
    text-transform: uppercase;
}

.btn-default:hover,
.btn-default:active,
.btn-default:focus {
    background: #B0C528;
    color: #fff;
}

/* Preloader */

#preloader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #000;
    z-index: 99999
}
#status {
    border: 5px solid #333;
    border-radius: 30px;
    height: 30px;
    left: 50%;
    margin: -15px 0 0 -15px;
    opacity: 0;
    position: absolute;
    top: 50%;
    width: 30px;
    animation: pulsate 1s ease-out;
    animation-iteration-count: infinite
}
@keyframes pulsate {
    0% {
        transform: scale(.1);
        opacity: 0.0;
    }
    50% {
        opacity: 1;
    }
    100% {
        transform: scale(1.2);
        opacity: 0;
    }
}
/*scroll to top*/

.scrollToTop {
  border-radius: 50%;
    bottom: 60px;
    display: none;
    font-size: 32px;
    font-weight: bold;
    height: 50px;
    position: fixed;
    right: 50px;
    text-align: center;
    text-decoration: none;
    width: 50px;
    z-index: 9999;
    color: #fff;
    background: #A7BE0E;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
}
.scrollToTop:hover,
.scrollToTop:focus {
    text-decoration: none;
    outline: none;
    background: #B8CB3E;
    color: #fff;
}

.carousel-inner{
	height: 450px;
}

/* Navigation */
#tf-menu {
	padding: 10px;
	transition: all 0.8s;
		font-family: 'Montserrat', sans-serif;

}
/*#tf-menu.navbar-default {
	background-color: rgba(248, 248, 248, 0);
	border-color: rgba(231, 231, 231, 0);
}*/
#tf-menu a.navbar-brand {
font-size: 22px;
color: #fff;
font-weight: 900;
}

.navbar-default .navbar-nav > .active > a {
	background: 0;
}

.navbar-brand img {
	width: 275px;	
}
// #tf-impact{
// 	color: white;
//     background: linear-gradient(-180deg, #009E00, #FFFF96);
// }

// #tf-impact .btn{
//     background:  #009E00;
// }
#tf-press a:before {
	z-index: 0;
    color: #363636;
    content: " ";
    width: 100px;
    height: 100px;
    background: rgba(245,245,245, .6);
	border: 4px solid #A7BE0E;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 50%;
}

#tf-impact{
	background: #efefef;
	padding: 80px 0px;
}

#tf-impact h4{
	padding: 20px 0px;
}

#tf-impact h4 a{
	color: #A7BE0E;
}

@media (max-width: 480px){
.navbar-brand img {
    width: 170px;
    position: relative;
    top: 8px;
}
#tf-relationships .service img{
	margin-top: 40px!important;
	}
}
#map iframe {
	width: 100%;
	height: 370px;
}
.navbar-right {
	/*margin-top: 13px;*/
    position: relative;
    top: 14px;
}

#tf-menu.navbar-default .navbar-nav > li > a {
	text-transform: uppercase;
	color: #363636;
	font-size: 14px;
	letter-spacing: 1px;
}
.on {
	background-color: #222222 !important;
	padding: 0 !important;
}

.navbar-fixed-top {
	position: relative;
}


#tf-menu.navbar-default .navbar-nav > li > a:active,
#tf-menu.navbar-default .navbar-nav > li > a:hover,
#tf-menu.navbar-default .navbar-nav > li > a:focus {
	color: #A7BE0E !important;
	background-color: transparent;
}

.navbar-toggle {
	border-radius: 0;
	margin-top: 18px;
	}
.navbar-default .navbar-toggle:hover,
.navbar-default .navbar-toggle:focus {
	background-color: #A7BE0E;
	border-color: #A7BE0E;
	}
.navbar-default .navbar-toggle:hover>.icon-bar {
	background-color: #FFF;
	}

.navbar-default {
    background-color: #fff !important;
    border-color: #fff !important;
}

.navbar-default .navbar-collapse {
    overflow: hidden;
}
/* Home Style */
#tf-home{
	height: 450px;
	background: url(../img/laptop.jpg);
	background-size: cover;
	background-position: center;
	background-attachment: fixed;
	background-repeat: no-repeat;
	color: #fff;
	font-family: 'Montserrat', sans-serif;
}

#tf-home .item{
	height: 100%;
    width: 100%;
    background-image: url(../img/laptop.jpg);
    background-size: cover;
	-webkit-background-size: cover!important;
     -moz-background-size: cover;
     -o-background-size: cover;
     background-size: cover;
    background-position: center;
    background-attachment: fixed;
    background-repeat: no-repeat;
	position: absolute;
	top: 0; 
 	left: 0; 
	min-width: 100%;
	min-height: 100%;
}
#tf-home .overlay{
	background: -moz-linear-gradient(top,  rgba(0,0,0,0.8) 0%, rgba(0,0,0,0.73) 17%, rgba(0,0,0,0.66) 35%, rgba(0,0,0,0.55) 62%, rgba(0,0,0,0.4) 100%); /* FF3.6+ */
	background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(0,0,0,0.8)), color-stop(17%,rgba(0,0,0,0.73)), color-stop(35%,rgba(0,0,0,0.66)), color-stop(62%,rgba(0,0,0,0.55)), color-stop(100%,rgba(0,0,0,0.4))); /* Chrome,Safari4+ */
	background: -webkit-linear-gradient(top,  rgba(0,0,0,0.8) 0%,rgba(0,0,0,0.73) 17%,rgba(0,0,0,0.66) 35%,rgba(0,0,0,0.55) 62%,rgba(0,0,0,0.4) 100%); /* Chrome10+,Safari5.1+ */
	background: -o-linear-gradient(top,  rgba(0,0,0,0.8) 0%,rgba(0,0,0,0.73) 17%,rgba(0,0,0,0.66) 35%,rgba(0,0,0,0.55) 62%,rgba(0,0,0,0.4) 100%); /* Opera 11.10+ */
	background: -ms-linear-gradient(top,  rgba(0,0,0,0.8) 0%,rgba(0,0,0,0.73) 17%,rgba(0,0,0,0.66) 35%,rgba(0,0,0,0.55) 62%,rgba(0,0,0,0.4) 100%); /* IE10+ */
	background: linear-gradient(to bottom,  rgba(0,0,0,0.8) 0%,rgba(0,0,0,0.73) 17%,rgba(0,0,0,0.66) 35%,rgba(0,0,0,0.55) 62%,rgba(0,0,0,0.4) 100%); /* W3C */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#cc000000', endColorstr='#66000000',GradientType=0 ); /* IE6-9 */
	height: 450px;
	position: absolute;
	width: 100%;
/*	height: 750px;
*/	background-attachment: fixed;
}
#tf-home p.lead{
	color: #fff;
}

#tf-home h1 {
	font-size: 42px;
}
@media (max-width: 480px) {
	#tf-home h1 {
    font-size: 28px;
	}
}
@media (max-width: 700px){
	#tf-home .item{
	height: 100%;
    width: 100%;
    background-image: url(../img/laptop-ios.png);
    background-size: cover;
	-webkit-background-size: cover!important;
     -moz-background-size: cover;
     -o-background-size: cover;
     background-size: cover;
    background-position: center;
    background-attachment: fixed;
    background-repeat: no-repeat;
	position: absolute;
	top: 0; 
 	left: 0; 
	min-width: 100%;
	min-height: 100%;
}
}

.content{
	position: relative;
	padding: 150px 0 0;
/*	padding: 20% 0 0;
*/}

a.fa.fa-angle-down {
	margin-bottom: 40px;
	padding: 10px 15px;
	color: #fff;
	border: 2px solid #b4b4b4;
	border-radius: 50%;
	font-size: 24px;
	margin-top: 60px;
/*	margin-top: 200px;
*/	transition: all 0.5s;
}
a.fa.fa-angle-down:hover{
	background: #A7BE0E;
	color: #ffffff;
	border: 2px solid #A7BE0E;
}
/* About Section */
#tf-about{
	padding: 80px 0;
}

#tf-about h2 {
	font-size: 24px;
    line-height: 32px;
}

ul.about-list{ margin: 30px 0 0 ;}
ul.about-list li{
	display: block;
	font-size: 16px;
	line-height: 30px;
	font-family: 'Open Sans', sans-serif;
}
ul.about-list li span{
	margin-right: 10px;
}

/* Team Section */
#tf-team{
	background: url(../img/mac.jpg);
	background-size: cover;
	background-position: center;
	background-attachment: fixed;
	background-repeat: no-repeat;
	color: #ffffff;
}
#tf-team .overlay{
	background: -moz-linear-gradient(top,  rgba(0,0,0,0.8) 0%, rgba(0,0,0,0.73) 17%, rgba(0,0,0,0.66) 35%, rgba(0,0,0,0.55) 62%, rgba(0,0,0,0.4) 100%); /* FF3.6+ */
	background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(0,0,0,0.8)), color-stop(17%,rgba(0,0,0,0.73)), color-stop(35%,rgba(0,0,0,0.66)), color-stop(62%,rgba(0,0,0,0.55)), color-stop(100%,rgba(0,0,0,0.4))); /* Chrome,Safari4+ */
	background: -webkit-linear-gradient(top,  rgba(0,0,0,0.8) 0%,rgba(0,0,0,0.73) 17%,rgba(0,0,0,0.66) 35%,rgba(0,0,0,0.55) 62%,rgba(0,0,0,0.4) 100%); /* Chrome10+,Safari5.1+ */
	background: -o-linear-gradient(top,  rgba(0,0,0,0.8) 0%,rgba(0,0,0,0.73) 17%,rgba(0,0,0,0.66) 35%,rgba(0,0,0,0.55) 62%,rgba(0,0,0,0.4) 100%); /* Opera 11.10+ */
	background: -ms-linear-gradient(top,  rgba(0,0,0,0.8) 0%,rgba(0,0,0,0.73) 17%,rgba(0,0,0,0.66) 35%,rgba(0,0,0,0.55) 62%,rgba(0,0,0,0.4) 100%); /* IE10+ */
	background: linear-gradient(to bottom,  rgba(0,0,0,0.8) 0%,rgba(0,0,0,0.73) 17%,rgba(0,0,0,0.66) 35%,rgba(0,0,0,0.55) 62%,rgba(0,0,0,0.4) 100%); /* W3C */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#cc000000', endColorstr='#66000000',GradientType=0 ); /* IE6-9 */
	height: auto;
	background-attachment: fixed;
	padding: 80px 0;
}
.section-title.center{
	padding: 30px 0;
}
.section-title h2,
.section-title.center h2{
	font-weight: 300;
}
.section-title.center .line{
	border-top: 4px solid #A7BE0E;
	height: 10px;
	width: 60px;
	text-align: center;
	margin: 0 auto;
	margin-top: 20px;
}

.section-title.center hr {
	border-top: 4px solid white;
	width: 40px;
	text-align: center;
	margin-top: 10px;
	position: relative;
	left: 17%;
}
#team{ margin: 0 auto}
#team .item{
    padding: 0;
    margin: 15px;
    color: #FFF;
    text-align: center;
}

.analytics {
	max-width: 225px;
	margin-left: 170px;
	margin-top: 40px;
}

@media (max-width: 1199px){
	.analytics {
		max-width: 225px;
		margin-left: 0;
		margin-top: 40px;
	}
}


img.img-circle.team-img {
	width: 150px;
	height: 174px;
	border: 4px solid transparent;
	transition: all 0.5s;
	border-radius: 5px !important;
}
#tf-team .item .thumbnail:hover>img.img-circle.team-img{
	border: 4px solid #A7BE0E;
}
#tf-team .thumbnail {
	background: transparent;
	border: 0;
}

#tf-team .thumbnail h3 {
	font-size: 24px;
}

#tf-team .thumbnail p:first-of-type {
	font-size: 18px;
}

#tf-team .thumbnail img {
	cursor: pointer;
}

#tf-team .thumbnail .caption {
	padding: 9px;
	color: #F2F2F2;
}

.owl-theme .owl-controls .owl-page span {
	display: block;
	width: 10px;
	height: 10px;
	margin: 5px 7px;
	filter: Alpha(Opacity=1);
	opacity: 1;
	-webkit-border-radius: 0;
	-moz-border-radius: 20px;
	border-radius: 0;
	background: #FFFFFF;
	transition: all 0.5s;
}

.owl-theme .owl-controls .owl-page.active span,
.owl-theme .owl-controls.clickable .owl-page:hover span {
	filter: Alpha(Opacity=100);
	opacity: 1;
	background: #A7BE0E;
}
.owl-theme .owl-controls .owl-page.active span{
	background: #A7BE0E;
}

/* Services Section */
#tf-services{
	padding: 80px 0;
}
#tf-press{
	padding: 0px;
	background: url(../img/press.jpg);
	background-size: cover;
	background-position: center;
	background-attachment: fixed;
	background-repeat: no-repeat;
	color: #efefef;
}
.space{
	margin-top: 40px;
}

#tf-services i.fa, #tf-press i.fa {
	position: relative;
	z-index: 1;
	font-size: 40px;
	border: 4px solid #A7BE0E;
	width: 100px;
	height: 100px;
	padding: 27px 25px;
	margin-bottom: 10px;
	border-radius: 50%;
	transition: all 0.5s;
}
#tf-press i.fa {
	font-size: 40px;
	color: #efefef;
	border: 4px solid #A7BE0E;
	width: 100px;
	height: 100px;
	padding: 27px 25px;
	margin-bottom: 10px;
	border-radius: 50%;
	transition: all 0.5s;
}
#tf-press img {
	padding-top: 20px;
	padding-bottom: 20px;
	position: relative;	
	z-index: 1;
	width: 80px;
	height: 100px;
	margin-bottom: 10px;
	transition: all 0.5s;
	
}

#tf-press img#prof200 {
	width: 40px;
    padding-bottom: 20px;
    height: 100px;
    padding-top: 20px;
    margin-bottom: 10px;
    transition: all 0.5s;
}

#tf-services i.fa.fa-mobile{
	font-size: 50px;
	padding: 20px 25px;
}

#tf-services .service:hover>i.fa,  #tf-press .service a:hover>i.fa{
	background: #A7BE0E;
	color: #ffffff;
}
#tf-press .service a:hover>i.fa:before{
	content: "\f0c1";
}
#tf-press a{
	color: #363636;
}
/* Clients Section */
#tf-clients{
	background: url(../img/04.jpg);
	background-size: cover;
	background-position: center;
	background-attachment: fixed;
	background-repeat: no-repeat;
	color: #ffffff;
}

#tf-press .overlay, #tf-clients .overlay{
	background: -moz-linear-gradient(top,  rgba(0,0,0,0.8) 0%, rgba(0,0,0,0.73) 17%, rgba(0,0,0,0.66) 35%, rgba(0,0,0,0.55) 62%, rgba(0,0,0,0.4) 100%); /* FF3.6+ */
	background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(0,0,0,0.8)), color-stop(17%,rgba(0,0,0,0.73)), color-stop(35%,rgba(0,0,0,0.66)), color-stop(62%,rgba(0,0,0,0.55)), color-stop(100%,rgba(0,0,0,0.4))); /* Chrome,Safari4+ */
	background: -webkit-linear-gradient(top,  rgba(0,0,0,0.8) 0%,rgba(0,0,0,0.73) 17%,rgba(0,0,0,0.66) 35%,rgba(0,0,0,0.55) 62%,rgba(0,0,0,0.4) 100%); /* Chrome10+,Safari5.1+ */
	background: -o-linear-gradient(top,  rgba(0,0,0,0.8) 0%,rgba(0,0,0,0.73) 17%,rgba(0,0,0,0.66) 35%,rgba(0,0,0,0.55) 62%,rgba(0,0,0,0.4) 100%); /* Opera 11.10+ */
	background: -ms-linear-gradient(top,  rgba(0,0,0,0.8) 0%,rgba(0,0,0,0.73) 17%,rgba(0,0,0,0.66) 35%,rgba(0,0,0,0.55) 62%,rgba(0,0,0,0.4) 100%); /* IE10+ */
	background: linear-gradient(to bottom,  rgba(0,0,0,0.8) 0%,rgba(0,0,0,0.73) 17%,rgba(0,0,0,0.66) 35%,rgba(0,0,0,0.55) 62%,rgba(0,0,0,0.4) 100%); /* W3C */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#cc000000', endColorstr='#66000000',GradientType=0 ); /* IE6-9 */
	padding: 80px 0;
	background-attachment: fixed;
}

#clients .item{
    padding: 0;
    margin: 35px;
    color: #FFF;
    text-align: center;
}
/* Gallery Section
==============================*/
#tf-works{
	padding: 80px 0;
}
.categories{
	padding: 10px 0;
}
ul.cat li{
	display: inline-block;
}
#tf-works li.pull-right {
	margin-top: 10px;
}
ol.type li{
	display: inline-block;
	margin-left: 20px;
}
ol.type li:after{
	content: ' | ';
	margin-left: 20px;
}
ol.type li:last-child:after { content: ''; }
ol.type li a{ color: #222222}
ol.type li a.active { font-weight: 700; }
.isotope-item { z-index: 2 }
.isotope-hidden.isotope-item { z-index: 1 }
.isotope,
.isotope .isotope-item {
    /* change duration value to whatever you like */
    -webkit-transition-duration: 0.8s;
    -moz-transition-duration: 0.8s;
    transition-duration: 0.8s;
}
.isotope-item {
    margin-right: -1px;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}
.isotope {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transition-property: height, width;
    -moz-transition-property: height, width;
    transition-property: height, width;
}
.isotope .isotope-item {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transition-property: -webkit-transform, opacity;
    -moz-transition-property: -moz-transform, opacity;
    transition-property: transform, opacity;
}
.portfolio-item{
	margin-bottom: 30px;
}
.portfolio-item .hover-bg{
	height: 260px;
	overflow: hidden;
	position: relative;
}

.hover-bg .hover-text {
	position: absolute;
	text-align: center;
	margin: 0 auto;
	color: #ffffff;
	background: rgba(0, 0, 0, 0.66);
	padding: 25% 0;
	height: 100%;
	width: 100%;
	opacity: 0;
    transition: all 0.5s;
}
.hover-bg .hover-text>h4{
	opacity: 0;
	-webkit-transform: translateY(100%);
            transform: translateY(100%);
            transition: all 0.3s;
}
.hover-bg:hover .hover-text>h4{
	opacity: 1;
    -webkit-backface-visibility:hidden;
    -webkit-transform: translateY(0);
            transform: translateY(0);
}
.hover-bg .hover-text>i{
	opacity: 0;
	-webkit-transform: translateY(0);
            transform: translateY(0);
            transition: all 0.3s;
}
.hover-bg:hover .hover-text>i{
	opacity: 1;
    -webkit-backface-visibility:hidden;
   -webkit-transform: translateY(100%);
            transform: translateY(100%);
}
.hover-bg:hover .hover-text{
	opacity: 1;
}
#tf-works i.fa {
	height: 30px;
	width: 30px;
	border: 1px solid #A7BE0E;
	font-size: 20px;
	padding: 5px;
	border-radius: 50%;
	color: #A7BE0E;
}
/* Testimonials Section */
#tf-testimonials{
	background: url(../img/04.jpg);
	background-size: cover;
	background-position: center;
	background-attachment: fixed;
	background-repeat: no-repeat;
	color: #ffffff;
}
#tf-relationships{
	padding: 80px 0px;
}

#tf-testimonials .overlay{
	background: -moz-linear-gradient(top,  rgba(0,0,0,0.8) 0%, rgba(0,0,0,0.73) 17%, rgba(0,0,0,0.66) 35%, rgba(0,0,0,0.55) 62%, rgba(0,0,0,0.4) 100%); /* FF3.6+
	background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(0,0,0,0.8)), color-stop(17%,rgba(0,0,0,0.73)), color-stop(35%,rgba(0,0,0,0.66)), color-stop(62%,rgba(0,0,0,0.55)), color-stop(100%,rgba(0,0,0,0.4))); /* Chrome,Safari4+ */
	background: -webkit-linear-gradient(top,  rgba(0,0,0,0.8) 0%,rgba(0,0,0,0.73) 17%,rgba(0,0,0,0.66) 35%,rgba(0,0,0,0.55) 62%,rgba(0,0,0,0.4) 100%); /* Chrome10+,Safari5.1+ */
	background: -o-linear-gradient(top,  rgba(0,0,0,0.8) 0%,rgba(0,0,0,0.73) 17%,rgba(0,0,0,0.66) 35%,rgba(0,0,0,0.55) 62%,rgba(0,0,0,0.4) 100%); /* Opera 11.10+ */
	background: -ms-linear-gradient(top,  rgba(0,0,0,0.8) 0%,rgba(0,0,0,0.73) 17%,rgba(0,0,0,0.66) 35%,rgba(0,0,0,0.55) 62%,rgba(0,0,0,0.4) 100%); /* IE10+ */
	background: linear-gradient(to bottom,  rgba(0,0,0,0.8) 0%,rgba(0,0,0,0.73) 17%,rgba(0,0,0,0.66) 35%,rgba(0,0,0,0.55) 62%,rgba(0,0,0,0.4) 100%); /* W3C */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#cc000000', endColorstr='#66000000',GradientType=0 ); /* IE6-9 */
	padding: 80px 0;
	background-attachment: fixed;
}
section#tf-relationships img{
	width: 150px;
	margin-top: 20px;
}
section#tf-relationships .row{
	margin-bottom: 40px;
	display: flex;
 	flex-wrap: wrap;

}
section#tf-relationships .section-title{
	padding-bottom: 0px;
}
#tf-testimonials h2 {
	color: #999;
}

#tf-testimonials p {
	color: #363636;
}

#testimonial {
	padding: 30px 0;
}
#testimonial .item{
    display: block;
    width: 100%;
    height: auto;
}

#testimonial .item p {
	font-weight: 400;
	margin: 30px 0;
	color: #d1d1d1;
}
/* Contact Section */
#tf-contact{
	padding: 80px 0;
}

label {
	float: left;
	font-size: 12px;
	font-weight: 400;
	font-family: 'Open Sans', sans-serif;
}
.switch {
	position: relative;
	display: inline-block;
	width: 30px;
	height: 14px;
	bottom: -5px;
	left: 5px;
}
.switch input{
	display: none;
}
.slider{
	position: absolute;
	cursor: pointer;
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;
	background: #ccc;
	-webkit-transition: .4s;
	transition: .4s;
}
.slider:before{
	position: absolute;
	content: "";
	height: 10px;
	width: 10px;
	left: 2px;
	bottom: 2px;
	background-color: white;
	-webkit-transition: .4s;
	transition: .4s;
}

input:checked + .slider{
	background-color: #A7BE0E;
}

input:checked + .slider:before {
	-webkit-transform: translateX(16px);
	-ms-transform: translateX(16px);
	transform: translateX(16px); 
}
#tf-contact .form-control {
	display: block;
	width: 100%;
	padding: 6px 12px;
	font-size: 14px;
	line-height: 1.42857143;
	color: #555;
	background-color: #fff;
	background-image: none;
	border: 1px solid #ccc;
	border-radius: 2px;
	-webkit-box-shadow: none;
	box-shadow: none;
	-webkit-transition: none;
	-o-transition: none;
	transition: none;
}

#tf-contact .form-control:focus {
	border-color: inherit;
	outline: 0;
	-webkit-box-shadow: transparent;
	box-shadow: transparent;
}

button.btn.tf-btn.btn-default {
	margin-top: 20px;
	background: #A7BE0E;
	border: 0;
	border-radius: 0;
	padding: 10px 40px;
	color: #ffffff;
	text-transform: uppercase;
}

button.btn.tf-btn.btn-default:hover,
button.btn.tf-btn.btn-default:active,
button.btn.tf-btn.btn-default:focus {
        background-color: #B8CB3E;
}

.btn:active, .btn.active {
	background-image: none;
	outline: 0;
	-webkit-box-shadow: none;
	box-shadow: none;
}

.btn:focus,
.btn:active:focus,
.btn.active:focus,
.btn.focus,
.btn:active.focus,
.btn.active.focus {
	outline: thin dotted;
	outline: none;
	outline-offset: none;
}
/* Footer */
nav#footer{
	background: #222222;
	color: #ffffff;
	padding: 20px 0 15px 0;
}
nav#footer .fnav{ vertical-align: middle;}
ul.footer-social li{
	display: inline-block;
	margin-right: 10px;
}
nav#footer p{
	font-size: 12px;
	margin-top: 10px;
}
#footer i.fa {
	height: 30px;
	width: 30px;
	border: 2px solid #8c8c8c;
	font-size: 20px;
	padding: 4px 5px;
	border-radius: 50%;
	color: #8c8c8c;
	transition: all 0.5s;
}
#footer i.fa:hover{
	background: #A7BE0E;
	border-color: #A7BE0E;
	color: #ffffff;}
